<template>
  <div>
    <div>
    <div class="cui__utils__heading">
      <strong>Velkommen til Sky Portalen</strong>
    </div>
  </div>
    <div class="row" v-if="loaded">
      <div class="col-xl-12 col-lg-12">
        <div class="card" v-for="post in posts" :key="post.id">
          <div class="card-body">
            <div class="mb-2">
              <a
                href="javascript: void(0);"
                class="text-dark font-size-24 font-weight-bold"
              >{{ post.title }}</a>
            </div>
            <div class="mb-3">
              <a class="font-weight-bold" href="javascript: void(0);">{{ post.author.name }}</a> skrev {{ moment(post.date).calendar(null, {
                  sameDay: '[I dag]',
                  nextDay: '[I morgen]',
                  nextWeek: 'dddd',
                  lastDay: '[I går]',
                  lastWeek: 'dddd',
                  sameElse: 'DD/MM/YYYY'
              }) }}
            </div>
            <div class="mb-4">
              <a
                href="javascript: void(0);"
                class="badge text-blue text-uppercase bg-light font-size-12 mr-2"
                v-for="(tag, i) in post.tags"
                :key="i"
              >{{ tag }}</a>
            </div>
            <div v-if="post.featured_media">
              <div class="col-lg-3">
                <img :src="post.featured_media" alt="">
              </div>
              <div
                v-html="post.content"
                class="col-lg-9"
              />
            </div>
            <div
              v-html="post.content"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import moment from 'moment'

export default {
  data() {
    return {
      // Wordpress Posts Endpoint
      postEndpoint: 'https://skygruppen.no/wp-json/wp/v2/posts?filter[cat]=28',
      queryOptions: {
        // password: 'SkyGruppenAS2021!!!',
        _embed: true, // Response should include embedded resources.
      },
      // Returned Posts in an Array
      posts: [],
      loaded: false,
    }
  },
  methods: {
    moment,
    getRecentPosts() {
      const ref = firestore.collection('news').where('status', '==', 'published').orderBy('date', 'desc')
      ref.onSnapshot(snapshot => {
        this.posts = []
        snapshot.forEach(doc => {
          this.posts.push(doc.data())
        })
        this.loaded = true
      })
    },
  },
  async mounted() {
    this.getRecentPosts()
  },
}
</script>

<style>
  .iframe-full iframe{
    width: 100%;
    height: calc(100vh - 85px);
  }

  .cui__utils__content{
    padding: 1em;
  }
</style>
